import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterThree from '../common/footer/FooterThree';

const Splash = () => {
    return (
        <>
           <SEO title="Home" />
           <main className="page-wrapper">
               <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

               {/* Start Slider Area  */}
               <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/new6-min.jpeg)`}}>
                   <div className="container">
                       <div className="row">
                           <div className="col-lg-12">
                               <div className="inner pt--80 text-center">
                                   <h1 id={'home_headline'} className="title display-one">Jos Group <br/> IT Technologies</h1>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
               {/* End Slider Area  */}

               <FooterThree/>
           </main>

        </>
    )
}

export default Splash;