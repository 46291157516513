import React, {Component, useEffect, useRef, useState} from 'react';
import LocalizedStrings from "react-localization";
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import ReCAPTCHA from 'react-google-recaptcha';

function ContactForm({props , formStyle}) {

    const localization = new LocalizedStrings({
        en: {
            name: "Name",
            phone: "Phone Number",
            subject: "Subject",
            message: "Message",
            submit: "Submit",
            result: "Your Message has been successfully sent. We will contact you soon.",
            not_valid_email: "Wrong Email"
        },
        sk: {
            name: "Meno",
            phone: "Tel. č.",
            subject: "Predmet",
            message: "Správa",
            submit: "Odoslať",
            result: "Vaša správa bola úspešne odoslaná. Budeme Vás čo najskôr kontaktovať.",
            not_valid_email: "Nesprávny email"
        },
        de: {
            name: "Name",
            phone: "Mobil",
            subject: "Gegenstand",
            message: "Botschaft",
            submit: "Senden",
            result: "Seine Nachricht ist erfolgreich versendt. Wir werden Sie bald kontaktieren.",
            not_valid_email: "Falsche Email"
        }
    });
    const [lang, setLang] = useState(navigator.language);
    useEffect(() => {
        if (window.localStorage.getItem('change_language') === 'sk')
            setLang('sk');
        else if (window.localStorage.getItem('change_language') === 'de')
            setLang('de');
        else if (window.localStorage.getItem('change_language') === 'en')
            setLang('en');
    })
    localization.setLanguage(lang);

    const Result = () => {
        return (
            <p className="success-message">{localization.result}</p>
        )
    }
    const [result, showresult] = useState(false);

    var DEBUG = false;
    if(!DEBUG){
        console.log = function() {}
    }

    class Post extends Component {
        state = {
            name: "",
            phone: "",
            email: "",
            subject: "",
            message: "",
            open: false,
            output: "",
            verify: false
        };

        onNameChange = e => {
            this.setState({
                name: e.target.value
            });
        };

        onPhoneChange = e => {
            this.setState({
                phone: e.target.value
            });
        };

        onEmailChange = e => {
            this.setState({
                email: e.target.value
            });
        };

        onSubjectChange = e => {
            this.setState({
                subject: e.target.value
            });
        };

        onMessageChange = e => {
            this.setState({
                message: e.target.value
            });
        };

        verifyCallback = () => {
            this.setState({
                verify: true
            });
        }

        handleSubmit = e => {
            e.preventDefault();
            if(!this.state.verify)
                return;
            axios
                .post("https://josgroup.sk/hodiny/email.php", this.state)
                .then(res => {this.setState({
                                output: res.data
                                });
                                this.setState({
                                    open: true
                                })
                             })
                .catch(err => {this.setState({
                                output: err.value
                                });
                                this.setState({
                                    open: true
                                })
                            });
            setTimeout(() => {window.location.reload();}, 5000);
        };

        render() {
            return (

                <form className={`${formStyle}`} action="" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            name="fullname"
                            placeholder={localization.name}
                            required
                            value={this.state.name}
                            onChange={this.onNameChange}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            required
                            value={this.state.email}
                            onChange={this.onEmailChange}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            name="phone"
                            placeholder={localization.phone}
                            required
                            value={this.state.phone}
                            onChange={this.onPhoneChange}
                        />
                    </div>


                    <div className="form-group">
                        <input
                            type="text"
                            name="subject"
                            placeholder={localization.subject}
                            required
                            value={this.state.subject}
                            onChange={this.onSubjectChange}
                        />
                    </div>

                    <div className="form-group">
                <textarea
                    name="message"
                    placeholder={localization.message}
                    required
                    value={this.state.message}
                    onChange={this.onMessageChange}
                >
                </textarea>
                    </div>

                    <div className="form-group">
                        <ReCAPTCHA
                            sitekey={"6LesXy0fAAAAAADqWJUHJB6CFi3AFiaXZXeSVgBs"}
                            onChange={this.verifyCallback}
                        />
                    </div>

                    <div className="form-group">
                        <button className="btn-default btn-large">{localization.submit}</button>
                    </div>

                    <div className="form-group">
                        {result ? <Result/> : null}
                    </div>

                    <Snackbar
                        open={this.state.open}
                        onClose={() => this.state.open = false}
                        message={this.state.output}
                    />
                </form>
            )
        }
    }
    return(
        <Post />
    )
}
export default ContactForm;
