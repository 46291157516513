import React from 'react';

const GermanLanguage = () => {
    const changeLanguageToGerman = () =>
    {
        window.localStorage.setItem('change_language', 'de');
    }
    return (
        <button className={'btn-default'} onClick={changeLanguageToGerman}>
            <img className={'btn-icon'} src={`${process.env.PUBLIC_URL}/images/icons/german-flag5.jpg`} alt="German flag" />
        </button>
    )
}
export default GermanLanguage