import React, {useEffect, useState} from 'react';
import CalltoActionEight from "../../elements/calltoaction/CalltoActionEight";
import CopyrightTwo from "./CopyrightTwo";
import ScrollTop from "./ScrollTop";
import {Link} from "react-router-dom";
import {FiFacebook, FiInstagram, FiLinkedin, FiTwitter} from "react-icons/fi";
import LocalizedStrings from "react-localization";


const FooterThree = () => {
    const localization = new LocalizedStrings({
        en:{
            contact: "Contact Us",
            privacy: "Privacy Policy"
        },
        sk:{
            contact: "Kontaktujte nás",
            privacy: "Ochrana osobných údajov"
        },
        de:{
            contact: "Kontakt uns",
            privacy: "Datenschutzrichtlinie"
        }
    });
    const [lang, setLang] = useState(navigator.language);
    useEffect(() => {
        if(window.localStorage.getItem('change_language') === 'sk')
            setLang('sk');
        else if(window.localStorage.getItem('change_language') === 'de')
            setLang('de');
        else if(window.localStorage.getItem('change_language') === 'en')
            setLang('en');
    })
    localization.setLanguage(lang);

    return (
        <>
            <div className="footer-style-2">
                <p>Jos Group s.r.o.</p>
                <div className="copyright-style-one">
                    <div className="align-items-center">
                        <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                            <div className="copyright-left">
                                <ul className="ft-menu link-hover">
                                    <li><Link to={"/privacy"}>{localization.privacy}</Link></li>
                                    <li><Link to={"/contact"}>{localization.contact}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-footer-widget">
                    <div className="inner">
                        <ul className="social-icon social-default justify-content-center">
                            <li><Link to="facebook.com"><FiFacebook /></Link></li>
                            <li><Link to="twitter.com"><FiTwitter /></Link></li>
                            <li><Link to="instagram.com"><FiInstagram /></Link></li>
                            <li><Link to="linkdin.com"><FiLinkedin /></Link></li>
                        </ul>
                    </div>
                </div>
            </div>*/}

            <ScrollTop />
        </>
    )
}
export default FooterThree;