import React, {useEffect, useState} from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import ContactOne from "./ContactOne";
import ScrollAnimation from "react-animate-on-scroll";
import LocalizedStrings from "react-localization";

const Contact = () => {
    const localization = new LocalizedStrings({
        en:{
            title: "Our Contact Address Here"
        },
        sk:{
            title: "Naše kontaktné údaje"
        },
        de:{
            title: "Kontaktinformation"
        }
    });
    const [lang, setLang] = useState(navigator.language);
    useEffect(() => {
        if(window.localStorage.getItem('change_language') === 'sk')
            setLang('sk');
        else if(window.localStorage.getItem('change_language') === 'de')
            setLang('de');
        else if(window.localStorage.getItem('change_language') === 'en')
            setLang('en');
    })
    localization.setLanguage(lang);

    return (
        <>
            <SEO title="Contact" />
            <Layout>
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="slider-area slider-style-5">
                                <div className="inner text-left">
                                    <h4 className="subtitle">JOS GROUP</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    {/*<SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Contact Form"
                                        title = "Our Contact Address Here."
                                        description = ""
                                    />*/}
                                    <div className={`section-title text-center`}>
                                        {/*<ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <h4 id={'contact-subtitle'} className={`subtitle`}><span className="theme-gradient">Contact Form</span></h4>
                                        </ScrollAnimation>*/}

                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <h2 className="title w-600 mb--20">{localization.title}</h2>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;