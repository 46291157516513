import React, {useEffect, useState} from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import LocalizedStrings from "react-localization";

const AboutTwo = () => {
    const localization = new LocalizedStrings({
        en:{
            title: "About Our Technologies",
            about_text: "We provide a comprehensive range of services for comprehensive coverage of customer needs in dealing with projects in the field of information systems. We handle projects of customers according to their requirements:",
            about1: "Analysis of customer requirements and their transformation into analityc system model",
            about2: "Implementation based on the specifications (specifications created by us or by the customer)",
            about3: "Deployment of information systems in pilot and routine operation",
            about4: "We have a broad base of experienced specialists",
            about5: "We speak Slovak, English and German"
        },
        sk:{
            title: "O našich technológiach",
            about_text: "Poskytujeme ucelenú paletu služieb pre komplexné pokrytie potrieb zákazníka pri riešení projektov v oblasti informačných systémov. Riešime projekty zákazníkov v závislosti od ich požiadaviek:",
            about1: "analýza požiadaviek zákazníka a ich transformácia do anylytického modelu",
            about2: "implementácia systému na základe špecifikácie (nami vytvorenej alebo špecifikácie od zákazníka)",
            about3: "nasadenie informačných systémov do pilotnej aj rutinnej prevádzky",
            about4: "disponujeme rozsiahlou bázou skúsených špecialistov",
            about5: "hovoríme po slovensky, anglicky a nemecky"
        },
        de:{
            title: "Um unsere Technologien",
            about_text: "Wir bieten eine umfassende Palette von Dienstleistungen für eine umfassende Abdeckung von Kunden zu lösen, braucht Projekte im Bereich der Informationssysteme . Wir lösen Projekte von Kunden entsprechend ihren Anforderungen:",
            about1: "Analyse der Kundenanforderungen und Umwandlung in den analytisches Modell.",
            about2: "Implementierung des Systems auf der Grundlage der Spezifikationen (Spezifikationen, die von uns oder vom Kunden erstellt)",
            about3: "Bereitstellung von Informationssystemen in Pilot- und Routinebetrieb",
            about4: "Wir verfügten über eine große Basis von erfahrenen Spezialisten",
            about5: "Wir sprechen Slowakisch, Englisch und Deutsch"
        }
    });
    const [lang, setLang] = useState(navigator.language);
    useEffect(() => {
        if(window.localStorage.getItem('change_language') === 'sk')
            setLang('sk');
        else if(window.localStorage.getItem('change_language') === 'de')
            setLang('de');
        else if(window.localStorage.getItem('change_language') === 'en')
            setLang('en');
    })
    localization.setLanguage(lang);

    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-left">
                    <div className="col-lg-5">
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title mt--10">{localization.title}</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>{localization.about_text}</p>

                                    <ul className="list-icon">
                                        <li id={"tech-about1"}><span className="icon"><FiCheck /></span>{localization.about1}</li>
                                        <li id={"tech-about2"}><span className="icon"><FiCheck /></span>{localization.about2}</li>
                                        <li id={"tech-about3"}><span className="icon"><FiCheck /></span>{localization.about3}</li>
                                        <li id={"tech-about4"}><span className="icon"><FiCheck /></span>{localization.about4}</li>
                                        <li id={"tech-about5"}><span className="icon"><FiCheck /></span>{localization.about5}</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
