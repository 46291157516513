import React from 'react';

const EnglishLanguage = () => {
    const changeLanguageToEnglish = () =>
    {
        window.localStorage.setItem('change_language', 'en');
    }
    return (
        <button className={'btn-default'} onClick={changeLanguageToEnglish}>
            <img className={'btn-icon'} src={`${process.env.PUBLIC_URL}/images/icons/british-flag3.png`} alt="British flag" />
        </button>
    )
}
export default EnglishLanguage

