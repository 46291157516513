import React from 'react';
import LocalizedStrings from "react-localization";

const SlovakLanguage = () => {
    const changeLanguageToSlovak = () =>
    {
        window.localStorage.setItem('change_language', 'sk');
    }
    return (
        <button className={'btn-default'} onClick={changeLanguageToSlovak}>
            <img className={'btn-icon'} src={`${process.env.PUBLIC_URL}/images/icons/slovak-flag2.jpg`} alt="Slovak flag" />
        </button>
    )
}
export default SlovakLanguage

