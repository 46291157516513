import React, {useEffect, useState} from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";
import ServiceTwo from "./ServiceTwo";
import ServiceThree from "./ServiceThree";
import ServiceFour from "./ServiceFour";
import ServiceFive from "./ServiceFive";
import Separator from "../separator/Separator";
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import {FiCheck} from "react-icons/fi";
import LocalizedStrings from "react-localization";

const Service = () => {
    const localization = new LocalizedStrings({
        en:{
            title: "Our Services",
            subtitle: "What we can do for you",
            service_title: "Services provide for you",
            description: "JOS GROUP Ltd. offers to participate in the development of the field of information technologies.\n" +
                "                                    Our company provides solutions with highest quality whose advantage is the professional knowledge of our specialists. the 100 % adherence\n" +
                "                                    to the planned budget, the high quality of the project's processing and its fast implementation.",
            leasing_title: "Personal leasing",
            leasing_description: "Advantage of these services:",
            leasing1: "Possibility of flexible and rapid response to the changing needs of workers (number, qualification, place of execution)",
            leasing2: "Saving time spent on recruitment and selection, and selection of employees is carried out by qualified experts",
            leasing3: "Transposition comprehensive personal and payroll administration for temporary workers",
            leasing4: "Creating sufficient time for employers in verifying the ability of future employee",
            leasing5: "Optimalisation number of employees and the follow-up to Labor costs",
            outsourcing_title: "Outsourcing",
            outsourcing_description: "JOS GROUP Company Ltd. Outsourcing offers in Slovakia, this service provides the following benefits:",
            outsourcing1: "It includes all the benefits of leasing",
            outsourcing2: "New technologies without ancillary costs",
            outsourcing3: "Advantageous hourly rate than for personal leasing"
        },
        sk:{
            title: "Naše služby",
            subtitle: "Čo môžeme pre Vás urobiť",
            service_title: "Poskytované služby",
            description: "Spoločnosť JOS GROUP s.r.o. ponúka služby v oblasti informačných technológií. Odborné znalosti a bohaté skúsenosti naších odborníkov príspievajú k vysokej kvalite ponúkaných riešení. Dokážeme zabezpečiť potrebné kapacity na realizáciu projektov a postarať sa o ich efektívnu implementáciu a úspešné ukončenie.",
            leasing_title: "Personálny leasing",
            leasing_description: "Aké výhody táto služba prináša:",
            leasing1: "Možnosť flexibilnej a rýchlej reakcie na meniacu sa potrebu pracovníkov (počet, kvalifikácia, miesto výkonu)",
            leasing2: "Úspora času stráveného pri nábore a výbere zamestnancov, pričom výber zamestnancov je realizovaný kvalifikovanými odborníkmi",
            leasing3: "Prebratie kompletnej personálnej a mzdovej administratívy za dočasne pridelených zamestnancov",
            leasing4: "Vytvorenie dostatočného časového priestoru pre zamestnávateľa pri preverovaní schopností budúceho zamestnanca",
            leasing5: "0ptimalizácia počtu pracovníkov a na to nadväzujúca úspora mzdových nákladov",
            outsourcing_title: "Outsourcing",
            outsourcing_description: "Spoločnosť JOS GROUP s.r.o. ponúka Outsourcing na Slovensku, táto služba prináša tieto výhody:",
            outsourcing1: "Zahŕňa všetky výhody personálneho leasingu",
            outsourcing2: "Nové technológie bez vedľajších nákladov",
            outsourcing3: "Výhodnejšia hodinová sadzba ako pri personálnom leasingu"
        },
        de:{
            title: "Unsere Service",
            subtitle: "Was wir für Sie tun können",
            service_title: "Dienstleistungen für Sie",
            description: "JOS GROUP Company Ltd. bietet Dienstleistungen in der Informationstechnologie. Know-how und reiche Erfahrung unserer Experten beitragen die hohe Qualität unserer Lösungen. Wir können die notwendigen Kapazitäten sicherzustellen, Projekte umzusetzen und für ihre wirksame Umsetzung zu sorgen und erfolgreichen Abschluss.",
            leasing_title: "Personal-Leasing",
            leasing_description: "Welche Vorteile dieser Service bringt:",
            leasing1: "Möglichkeit der flexiblen und schnellen Reaktion auf sich ändernde Bedürfnisse Arbeiter (Anzahl, Qualifikation, Ort der Ausführung)",
            leasing2: "Speichern von Zeitaufwand für die Rekrutierung und Auswahl von Mitarbeitern, während die Auswahl der Mitarbeiter wird von qualifizierten Experten durchgeführt",
            leasing3: "Die Umsetzung umfassende personal und Gehaltsabrechnung für Leiharbeiter",
            leasing4: "Schaffung einer ausreichenden Zeit für die Arbeitgeber für die Fähigkeit der zukünftigen Mitarbeiter untersuchen",
            leasing5: "Zahl der Mitarbeiter zu optimieren und weiterhin Einsparungen zu realisieren",
            outsourcing_title: "Outsourcing",
            outsourcing_description: "JOS GROUP Company Ltd. Outsourcing Angebote in der Slowakei, bietet dieser Service die folgenden Vorteile:",
            outsourcing1: "Es beinhaltet alle Vorteile von Leasing",
            outsourcing2: "Neue Technologien ohne Nebenkosten",
            outsourcing3: "Vorteilhafte Stundensatz als die persönliche Leasing"
        },
    });
    const [lang, setLang] = useState(navigator.language);
    useEffect(() => {
        if(window.localStorage.getItem('change_language') === 'sk')
            setLang('sk');
        else if(window.localStorage.getItem('change_language') === 'de')
            setLang('de');
        else if(window.localStorage.getItem('change_language') === 'en')
            setLang('en');
    })
    localization.setLanguage(lang);

    return (
        <>
            <SEO title="Service" />
            <Layout>
                <div className="breadcrumb-area breadcarumb-style-1 ptb--120">
                <div className="slider-area slider-style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-left">
                                    <h4 className="subtitle">JOS GROUP</h4>
                                </div>
                                <div className="inner text-center">
                                    <h1 className="title display-one">{localization.title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="main-content">

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/*<SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = ""
                                     />*/}
                                    <div className={`section-title text-center`}>
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <h4><span id={'service-subtitle'} className="theme-gradient" >{localization.subtitle}</span></h4>
                                        </ScrollAnimation>

                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <h2 id={'service-title'} className="title w-600 mb--20">{localization.service_title}</h2>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                            {/*<ServiceOne />*/}

                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="description">{localization.description}</p>
                            </ScrollAnimation>

                            <div className="row row--15 service-wrapper">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={0}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <div className={"service service__style--1 bg-color-blackest radius mt--25 text-center"}>
                                            <div className="icon">
                                                {/*{val.icon}*/}
                                            </div>
                                            <div className="content">
                                                <h4 className="title w-600"><Link to="#service">{localization.leasing_title}</Link></h4>
                                                <p id={'service-leasing-description'} className="description b1 color-gray mb--0">{localization.leasing_description}</p>
                                                <ul className="list-icon">
                                                    <li><span className="icon"><FiCheck /></span>{localization.leasing1}</li>
                                                    <li><span className="icon"><FiCheck /></span>{localization.leasing2}</li>
                                                    <li><span className="icon"><FiCheck /></span>{localization.leasing3}</li>
                                                    <li><span className="icon"><FiCheck /></span>{localization.leasing4}</li>
                                                    <li><span className="icon"><FiCheck /></span>{localization.leasing5}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={1}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <div className={"service service__style--1 bg-color-blackest radius mt--25 text-center"}>
                                            <div className="icon">
                                                {/*{val.icon}*/}
                                            </div>
                                            <div className="content">
                                                <h4 className="title w-600"><Link to="#service">{localization.outsourcing_title}</Link></h4>
                                                <p className="description b1 color-gray mb--0">{localization.outsourcing_description}</p>
                                                <ul className="list-icon">
                                                    <li id={"services-outsourcing1"}><span className="icon"><FiCheck /></span>{localization.outsourcing1}</li>
                                                    <li id={"services-outsourcing2"}><span className="icon"><FiCheck /></span>{localization.outsourcing2}</li>
                                                    <li id={"services-outsourcing3"}><span className="icon"><FiCheck /></span>{localization.outsourcing3}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>

                            </div>

                        </div>
                    </div>
                    {/* End Service Area  */}

                    
                    <Separator />

                    {/* Start Service Area  */}
                    {/*<div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                                textAlign = "text-left"
                             />
                        </div>
                    </div>*/}
                    {/* End Service Area  */}

                    {/*<Separator />*/}

                    {/* Start Service Area  */}
                    {/*<div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-left"
                             />
                        </div>
                    </div>*/}
                    {/* End Service Area  */}

                    {/*<Separator />*/}

                    {/* Start Service Area  */}
                    {/*<div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceThree 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>*/}
                    {/* End Service Area  */}

                    {/*<Separator />*/}


                    {/* Start Service Area  */}
                    {/*<div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 icon-circle-style"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>/*}
                    {/* End Service Area  */}


                    {/*<Separator />*/}


                    {/* Start Service Area  */}
                    {/*<div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceFour 
                                serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>*/}
                    {/* End Service Area  */}


                    {/*<Separator />*/}

                    {/* Start Service Area  */}
                    {/*<div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceFive 
                                serviceStyle = "gallery-style"
                                textAlign = "text-left"
                            />
                        </div>
                    </div>*/}
                    {/* End Service Area  */}

                    
                </div>

            </Layout>
            
        </>
    )
}

export default Service;
