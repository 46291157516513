import React, {useEffect, useState} from 'react';
import SEO from "../common/SEO";
import LocalizedStrings from "react-localization";
import Layout from "../common/Layout";


const Privacy = () => {
    const localization = new LocalizedStrings({
        en:{
            title: "Privacy Policy",
            line1: "Privacy Policy and GDPR Privacy Policy\n" +
                "The controller (controller) of your personal data pursuant to Article 4(7). Regulation (EU) 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (GDPR) is a company:",
            country: "Slovakia",
            id: "ID",
            contact: "The administrator's contact details are",
            mobil: "Phone",
            line2: "( hereinafter referred to as \"the controller\" in the principles )",
            processing: "Legal reason for the processing of personal data",
            line3: "The legal basis for the processing of personal data with the controller on data subjects of all categories is the following provisions of the GDPR or the new Personal Data Protection Act",
            line4: "the processing of personal data is necessary for the performance of the contract to which the data subject is a party, or for the implementation of the measure before the conclusion of the contract at the request of the data subject, - pursuant to § 13(1)(b) of the privacy policy, respectively, art. 6(b) gdpr.",
            line5: "(orders, works contracts, sales contracts, framework contracts, employment contracts, CVs....)",
            line6: "Pursuant to § 78 para. 3 of the privacy policy, the controller, who is the employer of the data subject, is entitled to provide his/her personal data or to publish his/her personal data within the scope of title, first name, surname, job title, job title, function assignment, employee's personal number or employee number, professional unit, place of work,",
            line7: "the telephone number, fax number, e-mail address at the workplace and the employer's identification data, if necessary in connection with the performance of the work duties, duties or functional duties of the person concerned. The provision of personal data or the disclosure of personal data shall not undermine the seriousness, dignity and security of the data subject. (employee data)",
            line8: "the processing of personal data is necessary according to a special regulation or international treaty by which the Slovak Republic is bound, - pursuant to § 13(1)(c) of the privacy policy, respectively Art. 6(c) GDPR (Cadastral Act, Commercial Code, Labour Code, Health Insurance Act, Social Insurance Act, Income Tax Act....)",
            line9: "the processing of personal data is necessary for the purpose of the legitimate interests of the controller (Act No. 124/2006 Coll., Act No. 311/2001 Coll., Act No. 314/2001 Coll., Act No. 18/2018 Coll., Act No. 395/2002 Coll., etc.) or a third party, except where those interests are overridden by the interests or rights of the data subject requiring the protection of personal data, in particular if the data subject is a child, - pursuant to § 13(1)(f) of the privacy policy, or Article 6(f) gdpr.",
            line10: "further processing of personal data for archiving purposes, for scientific purposes, for the purpose of historical research or for statistical purposes, where it complies with a specific regulation and where adequate safeguards are respected for the protection of the rights of the data subject.",
            line11: "Statement",
            line12: "We declare that, as the controller of your personal data, we comply with all legal obligations required by the applicable legislation, in particular the Personal Data Protection Act and gdpr, and therefore that:",
            line13: "we will process your personal data only on the basis of the valid legal reason described above.",
            line14: "In accordance with Article 13 GDPR, we hereby fulfil the information obligation towards the data subjects",
            line15: "we will allow you and we will support you in exercising and fulfilling your rights under the new Privacy policy Act and gdpr.",
            line16: "Subjects:",
            line17: "Contractual and pre-contractual trading partners",
            line18: "Customers of the operator",
            line19: "Employees of the operator",
            line20: "Purposes of processing:",
            line21: "We process personal data that you entrust to us yourself for the purpose of fulfilling the following purposes:",
            line22: "Contractual/pre-contractual partners and customers of the operator:",
            line23: "identification of the Contracting Party",
            line24: "provision of the agreed service",
            line25: "How long will we collect your personal data?",
            line26: "The Controller guarantees that the personal data provided by the data subject in the contract in question or otherwise will be processed in the information systems in accordance with the principle of minimizing storage and in the event that the purpose of the processing ceases, the controller guarantees to erase the personal data. In the event that such personal data are processed for a purpose other than that provided for in this Article above, the data subject shall be informed of this purpose as well as of the legal basis of such processing prior to such processing.",
            line27: "The Controller guarantees that the personal data of employees for the purposes specified in this Policy will be processed for a maximum period of the duration of the employment relationship, or if any of the special regulations so require, for the period specified in this Legal Regulation.",
            line28: "Security and privacy",
            line29: "We protect personal data to the maximum extent possible. We protect them as if they were our own. We have put in place all possible technical and organizational measures to prevent the misuse, damage or destruction of your personal data.",
            line30: "Transfer of personal data to third parties (intermediaries, recipients)",
            line31: "Your personal data can be accessed by authorised persons authorised by the controller who are bound by confidentiality and retrained in the field of processing security.",
            line32: "We manage most of the processing operations on our own and do not need 3rd parties to do so.\n" +
                "In order to ensure some specific processing operations that we cannot provide on our own, we use the services of intermediaries who specialize in the processing and are obliged by an intermediary contract in accordance with gdpr.",
            line33: "These are providers of the following platforms and services:",
            line34: "Supplier of accounting services",
            line35: "Supplier of IT services",
            line36: "Transfer of data outside the European Union",
            line37: "We process data exclusively in the European Union or in countries that ensure a level of protection as decided by the European Commission.",
            line38: "Your rights in relation to the protection of personal data:",
            line39: "You have several rights in relation to the protection of personal data. If you wish to exercise any of these rights, please contact us via the email address above.",
            line40: "You have",
            line41: "the right to information",
            line42: "that is already fulfilled by this privacy policy. - information obligation",
            line43: "Thanks to",
            line44: "the right of access,",
            line45: "you can invite us at any time and we will prove to you within 30 days what of your personal data we process and why.",
            line46: "If anything changes for you or you see any of your personal data out of date or incomplete, you have",
            line47: "the right to add and change your personal data.",
            line48: "You may exercise",
            line49: "the right to restrict processing",
            line50: "if you believe that we are processing your inaccurate data, believe that we are processing unlawfully, but do not want to delete all the data or if you object to the processing.",
            line51: "You can",
            line52: "limit",
            line53: "the scope of personal data or the purposes of the processing.",
            line54: "Right to erasure (to be forgotten):",
            line55: "Your next right is the right to erasure, in the absence of a legal basis that entitles us or a legal obligation that orders us to process your personal data. In this case, we will delete all your personal data from your system, also from the system of all partial processors and advances within the statutory time limit.",
            line56: "Right to data portability",
            line57: "in the cases foreseen by the Regulation, you have the right to obtain personal data concerning you which concern you and which you have provided to the Controller in a structured, commonly used and machine-readable format, with this right not adversely affecting the rights and freedoms of other persons.",
            line58: "You may",
            line59: "object",
            line60: "at any time to the processing of your personal data with the Controller for direct marketing purposes carried out on the basis of a legitimate interest.",
            line61: "Right to withdraw consent:",
            line62: "if the processing of your personal data is based on consent, you have the right to withdraw your consent to the processing of personal data for the purpose for which you gave your consent at any time.",
            line63: "Complaint with the Office for Personal Data Protection:",
            line64: "If you feel that we do not treat your data in accordance with the law, you have the right to contact the Office for Personal Data Protection at any time with your complaint.",
            line65: "The Supervisory Authority in the Slovak Republic is the Office for Personal Data Protection, with its registered office at Hraničná 12, 820 07 Bratislava 27, dataprotection.gov.sk",
            line66: "We will be very happy if you first inform us of this suspicion so that we can do something about it and correct any misconduct.",
            line67: "How can you exercise these rights?",
            line68: "You can contact us with your request in one of the following ways:",
            line69: "Email:",
            line70: "in writing or in person by delivery to:",
            line71: "If you believe that your rights to the protection of personal data have been violated, you have the right to lodge a complaint with the supervisory authority, which is the Office for Personal Data Protection at",
            line72: "Slovak Republic",
            line73: "Identification data:",
            line74: "VAT",
            line75: "The Secretariat of the Office shall:",
            line76: "Registry:",
            line77: "Monday - Thursday:",
            line78: "Friday:",
            line79: "WHERE CAN YOU CONTACT US?",
            line80: "If you have any comments, questions or requests regarding the use of your personal data, or if you have any questions about this privacy statement, please contact us at the following e-mail address: osadsky.jan@josgroup.sk or in the contact details provided in the Contact Us section. We welcome your questions and suggestions on this statement."
        },
        sk:{
            title: "Ochrana osobných údajov",
            line1: "Zásady spracovania osobných údajov a systém ochrany osobných údajov podľa nariadenia GDPR\n" +
                "Prevádzkovateľ (správca) Vašich osobných údajov podľa článku 4 bod 7. Nariadenia Európskeho parlamentu a rady (EÚ) 2016/679 o ochrane fyzických osôb v súvislosti so spracovaním osobných údajov a o voľnom pohybe takýchto údajov (ďalej len GDPR) je spoločnosť :",
            country: "Slovensko",
            id: "IČO",
            contact: "Kontaktné údaje správcu sú",
            mobil: "Tel. č.",
            line2: "( ďalej v texte zásad len ako \"prevádzkovateľ\" )",
            processing: "Zákonný dôvod spracovania osobných údajov",
            line3: "Právnym základom spracovania osobných údajov u prevádzkovateľa o dotknutých osobách všetkých kategórií sú nasledujúce ustanovenia GDPR, resp. nového zákona ochrany osobných údajov",
            line4: "spracovanie osobných údajov je nevyhnutné na plnenie zmluvy, ktorej zmluvnou stranou je dotknutá osoba, alebo na vykonanie opatrenia pred uzatvorením zmluvy na základe žiadosti dotknutej osoby, - podľa § 13 ods. 1 písm. b) ZOOÚ, resp. čl. 6 písm. b) GDPR.",
            line5: "(objednávky, zmluvy o dielo, kúpne zmluvy, rámcové zmluvy, pracovné zmluvy, životopisy....)",
            line6: "Podľa § 78 ods. 3 ZOOÚ prevádzkovateľ, ktorý je zamestnávateľom dotknutej osoby, je oprávnený poskytovať jej osobné údaje alebo zverejniť jej osobné údaje v rozsahu titul, meno, priezvisko, pracovné zaradenie, služobné zaradenie, funkčné zaradenie, osobné číslo zamestnanca alebo zamestnanecké číslo zamestnanca, odborný útvar, miesto výkonu práce,",
            line7: "telefónne číslo, faxové číslo, adresa elektronickej pošty na pracovisko a identifikačné údaje zamestnávateľa, ak je to potrebné v súvislosti s plnením pracovných povinností, služobných povinností alebo funkčných povinností dotknutej osoby. Poskytovanie osobných údajov alebo zverejnenie osobných údajov nesmie narušiť vážnosť, dôstojnosť a bezpečnosť dotknutej osoby. (údaje o zamestnancoch)",
            line8: "spracovanie osobných údajov je nevyhnutné podľa osobitného predpisu alebo medzinárodnej zmluvy, ktorou je Slovenská republika viazaná, - podľa § 13 ods. 1 písm. c) ZOOÚ, resp. čl. 6 písm. c) GDPR (katastrálny zákon, obchodný zákonník, zákonník práce, zákon o zdravotnom poistení, zákon o sociálnom poistení, zákon o dani z príjmov....)",
            line9: "spracovanie osobných údajov je nevyhnutné na účel oprávnených záujmov prevádzkovateľa ( zákon č. 124/2006 Z.z., zákon č. 311/2001 Z.z., zákon č. 314/2001 Z.z., zákon č. 18/2018 Z.z., zákon č. 395/2002 Z.z. a pod. )alebo tretej strany okrem prípadov, keď nad týmito záujmami prevažujú záujmy alebo práva dotknutej osoby vyžadujúce si ochranu osobných údajov, najmä ak je dotknutou osobu dieťa, - podľa § 13 ods. 1 písm. f) ZOOÚ, resp. čl. 6 písm. f) GDPR",
            line10: "ďalšie spracovanie osobných údajov na účel archivácie, na vedecký účel, na účel historického výskumu alebo na štatistický účel, ak je v súlade s osobitným predpisom a ak sú dodržané primerané záruky ochrany práv dotknutej osoby.",
            line11: "Prehlásenie",
            line12: "Prehlasujeme, že ako prevádzkovateľ vašich osobných údajov spĺňame všetky zákonné povinnosti vyžadované platnou legislatívou, najmä Zákonom o ochrane osobných údajov a GDPR, a teda že:",
            line13: "budeme spracovávať vaše osobné údaje len na základe platného právneho dôvodu popísaného vyššie.",
            line14: "Si týmto plníme podľa článku 13 GDPR informačnú povinnosť voči dotknutým osobám",
            line15: "umožníme vám a budeme vás podporovať v uplatňovaní a plnení vašich práv podľa nového zákona ZOOÚ a nariadenia GDPR.",
            line16: "Dotknuté osoby:",
            line17: "Zmluvní a predzmluvní obchodní partneri",
            line18: "Zákazníci prevádzkovateľa",
            line19: "Zamestnanci prevádzkovateľa",
            line20: "Účely spracovania:",
            line21: "Spracovávame osobné údaje, ktoré nám zveríte sami a to z dôvodov naplnenia týchto účelov:",
            line22: "Zmluvní/predzmluvní partneri a zákazníci prevádzkovateľa:",
            line23: "identifikácia zmluvnej strany",
            line24: "poskytnutie dohodnutej služby",
            line25: "Ako dlho budeme zhromažďovať Vaše osobné údaje?",
            line26: "Prevádzkovateľ sa zaručuje, že osobné údaje poskytnuté dotknutou osobou v predmetnej zmluve alebo iným spôsobom bude spracovávať v informačných systémoch v zmysle zásady minimalizácie uchovávania a v prípade, žeby odpadol účel spracovania, prevádzkovateľ sa zaručuje osobné údaje vymazať. V prípade, že budú uvedené osobné údaje spracovávané na iný účel ako je stanovený vyššie v tomto článku bude dotknutá osoba o tomto účele ako aj o právnom základe takého spracovávania informovaná ešte pred takým spracovaním.",
            line27: "Prevádzkovateľ sa zaručuje, že osobné údaje zamestnancov na účely špecifikované v týchto zásadách bude spracovávať maximálne po dobu trvania pracovnoprávneho vzťahu, prípadne ak si to vyžaduje niektorý s osobitných predpisov tak po dobu stanovenú v tomto zákonnom predpise.",
            line28: "Zabezpečenie a ochrana osobných údajov",
            line29: "Chránime osobné údaje v maximálnej možnej miere. Chránime ich ako keby boli naše vlastné. Zaviedli sme všetky možné technické a organizačné opatrenia, ktoré zamedzujú zneužitiu, poškodeniu alebo zničeniu vašich osobných údajov.",
            line30: "Odovzdanie osobných údajov tretím osobám ( sprostredkovatelia, príjemci)",
            line31: "K vašim osobným údajom majú prístup oprávnené osoby poverené prevádzkovateľom, ktorí sú viazaní mlčanlivosťou a preškolení v oblasti bezpečnosti spracovania.",
            line32: "Väčšinu spracovateľských operácií zvládame sami a nepotrebujeme k nim 3. strany.\n" +
                "Pre zaistenie niektorých konkrétnych spracovateľských operácií, ktoré nedokážeme zabezpečiť vlastnými silami, využívame služby sprostredkovateľov, ktorí sa na dané spracovanie špecializujú a sú zaviazaný sprostredkovateľskou zmluvou súlade s GDPR.",
            line33: "Sú to poskytovatelia nasledujúcich platforiem a služieb:",
            line34: "Dodávateľ účtovných služieb",
            line35: "Dodávateľ IT služieb",
            line36: "Odovzdávanie dát mimo Európskej únie",
            line37: "Dáta spracovávame výhradne v Európskej únii alebo v krajinách, ktoré zaisťujú stupeň ochrany na základe rozhodnutia Európskej komisie.",
            line38: "Vaše práva v súvislosti s ochranou osobných údajov :",
            line39: "V súvislosti s ochranou osobných údajov máte niekoľko práv. Ak budete chcieť niektorého z týchto práv využiť, prosím kontaktujte nás prostredníctvom vyššie uvedenej mailovej adresy.",
            line40: "Máte",
            line41: "právo na informácie,",
            line42: "ktoré sú plnené už týmito zásadami spracovania osobných údajov. - informačná povinnosť",
            line43: "Vďaka",
            line44: "právu na prístup",
            line45: "nás môžete kedykoľvek vyzvať a my vám doložíme v lehote 30 dní, aké vaše osobné údaje spracovávame a prečo.",
            line46: "Ak sa u vás niečo zmení alebo akékoľvek svoje osobné údaje zbadáte neaktuálne alebo neúplné, máte",
            line47: "právo na doplnenie a zmenu osobných údajov.",
            line48: "",
            line49: "Právo na obmedzenie spracovania",
            line50: "môžete využiť, ak sa domnievate, že spracovávame vaše nepresné údaje, myslíte si, že vykonávame spracovanie nezákonne, ale nechcete všetky údaje vymazať alebo ak máte námietku proti spracovaniu.",
            line51: "",
            line52: "Obmedziť",
            line53: "môžete rozsah osobných údajov alebo účelov spracovania.",
            line54: "Právo na výmaz (byť zabudnutý):",
            line55: "Vašim ďalším právom je právo na vymazanie, v prípade že neexistuje zákonný právny základ, ktorý nás oprávňuje resp. zákonná povinnosť, ktorá nám prikazuje vaše osobné údaje spracovať. V takom prípade vymažeme všetky vaše osobné údaje zo svojho systému, aj zo systému všetkých čiastkových spracovateľov a záloh v zákonom stanovenej lehote.",
            line56: "Právo na prenositeľnosť údajov",
            line57: "v prípadoch predpokladaných Nariadením máte právo získať osobné údaje, ktoré sa Vás týkajú, a ktoré ste poskytli Prevádzkovateľovi, v štruktúrovanom, bežne používanom a strojovo čitateľnom formáte s tým, že týmto právom nesmú byť nepriaznivo dotknuté práva a slobody iných osôb.",
            line58: "",
            line59: "Právo vzniesť námietku",
            line60: "môžete kedykoľvek vzniesť námietku proti spracovaniu Vašich osobných údajov u Prevádzkovateľa na účely priameho marketingu vykonávaného na základe oprávneného záujmu.",
            line61: "Právo na odvolanie súhlasu:",
            line62: "ak je spracovanie Vašich osobných údajov založené na súhlase, máte právo Váš súhlas so spracovaním osobných údajov na účel, na ktorý ste dali súhlas, kedykoľvek odvolať.",
            line63: "Sťažnosť na Úrade na ochranu osobných údajov:",
            line64: "Ak máte pocit, že s vašimi údajmi nezaobchádzame v súlade so zákonom, máte právo sa so svojou sťažnosťou kedykoľvek obrátiť na Úrad na ochranu osobných údajov. ",
            line65: "Dozorným úradom je v SR Úrad na ochranu osobných údajov, so sídlom Hraničná 12, 820 07 Bratislava 27, dataprotection.gov.sk",
            line66: "Budeme veľmi radi, ak najprv budete o tomto podozrení informovať nás, aby sme s tým mohli niečo urobiť a prípadné pochybenie napraviť.",
            line67: "Ako môžete tieto práva vykonávať?",
            line68: "S Vašou žiadosťou sa môžete na nás obrátiť niektorým z týchto spôsobov:",
            line69: "emailom:",
            line70: "písomne alebo osobne doručením na adresu:",
            line71: "V prípade, že sa domnievate ,že došlo k porušeniu Vašich práv na ochranu osobných údajov máte právo podať sťažnosť dozornému orgánu ktorým je Úrad na ochranu osobných údajov na adrese",
            line72: "Slovenská republika",
            line73: "Identifikačné údaje:",
            line74: "DIČ",
            line75: "Sekretariát úradu:",
            line76: "Podateľňa:",
            line77: "pondelok - štvrtok:",
            line78: "piatok:",
            line79: "KDE NÁS MÔŽETE KONTAKTOVAŤ?",
            line80: "Ak máte akékoľvek pripomienky, otázky či žiadosti týkajúce sa používania vašich osobných údajov, alebo ak máte akúkoľvek otázku týkajúcu sa tohto vyhlásenia o ochrane osobných údajov, kontaktujte nás na tejto e-mailovej adrese: osadsky.jan@josgroup.sk alebo v kontaktných údajoch uvedených v sekcii Kontaktujte nás. Vaše otázky a návrhy týkajúce sa tohto vyhlásenia uvítame."
        },
        de:{
            title: "Datenschutzrichtlinie",
            line1: "Datenschutzerklärung und DSGVO-Datenschutzerklärung\n" +
                "Verantwortlicher (Verantwortlicher) Ihrer personenbezogenen Daten gemäß Art. 4 Abs. 7. Die Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten und zum freien Datenverkehr (DSGVO) ist ein Unternehmen:",
            country: "Slowakei",
            id: "ID",
            contact: "Die Kontaktdaten des Administrators lauten wie folgt",
            mobil: "Mobil",
            line2: "( im Folgenden in den Grundsätzen als \"der Verantwortliche\" bezeichnet )",
            processing: "Rechtlicher Grund für die Verarbeitung personenbezogener Daten",
            line3: "Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten bei dem Verantwortlichen für Betroffene aller Kategorien sind die folgenden Bestimmungen der DSGVO oder des neuen Gesetzes über den Schutz personenbezogener Daten",
            line4: "die Verarbeitung personenbezogener Daten ist zur Erfüllung des Vertrages, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung der Maßnahme vor Vertragsschluss auf Antrag der betroffenen Person erforderlich, - gemäß § 13 Abs. 1 lit. b) Datenschutzrichtlinie bzw. Art. 6 Buchst. b) DSGVO.",
            line5: "(Aufträge, Werkverträge, Kaufverträge, Rahmenverträge, Arbeitsverträge, Lebensläufe....)",
            line6: "Gemäß § 78 Abs. 3 Datenschutzrichtlinie ist der für die Verarbeitung Verantwortliche, der Arbeitgeber der betroffenen Person ist, berechtigt, seine personenbezogenen Daten bereitzustellen oder seine personenbezogenen Daten im Rahmen von Anrede, Vorname, Nachname, Berufsbezeichnung, Berufsbezeichnung, Funktionszuweisung, Personalnummer oder Mitarbeiternummer des Mitarbeiters, Berufseinheit, Arbeitsort,",
            line7: "die Telefonnummer, Faxnummer, E-Mail-Adresse am Arbeitsplatz und die Identifikationsdaten des Arbeitgebers, gegebenenfalls im Zusammenhang mit der Erfüllung der Arbeitsaufgaben, Pflichten oder Funktionspflichten der betroffenen Person. Die Bereitstellung personenbezogener Daten oder die Offenlegung personenbezogener Daten dürfen die Schwere, Würde und Sicherheit der betroffenen Person nicht beeinträchtigen. (Mitarbeiterdaten)",
            line8: "die Verarbeitung personenbezogener Daten ist gemäß einer besonderen Verordnung oder einem internationalen Vertrag, an den die Slowakische Republik gebunden ist, erforderlich, - gemäß § 13 Absatz 1 Buchstabe c der Datenschutzerklärung bzw. Art. 6 (c) DSGVO (Katastergesetz, Handelsgesetzbuch, Arbeitsgesetzbuch, Krankenversicherungsgesetz, Sozialversicherungsgesetz, Einkommensteuergesetz....)",
            line9: "die Verarbeitung personenbezogener Daten zur Wahrung der berechtigten Interessen des für die Verarbeitung Verantwortlichen (Gesetz Nr. 124/2006 Slg., Gesetz Nr. 311/2001 Slg., Gesetz Nr. 314/2001 Slg., Gesetz Nr. 18/2018 Slg., Gesetz Nr. 395/2002 Slg. usw.) oder eines Dritten erforderlich ist, es sei denn, diese Interessen werden durch die Interessen oder Rechte der betroffenen Person, die den Schutz personenbezogener Daten erfordern, außer Kraft gesetzt,  insbesondere, wenn es sich bei der betroffenen Person um ein Kind handelt, - gemäß § 13 Abs. 1 lit. f der Datenschutzerklärung oder Art. 6 lit. f DSGVO.",
            line10: "Weiterverarbeitung personenbezogener Daten zu Archivzwecken, zu wissenschaftlichen Zwecken, zum Zwecke der historischen Forschung oder zu statistischen Zwecken, wenn sie einer spezifischen Regelung entspricht und angemessene Garantien zum Schutz der Rechte der betroffenen Person beachtet werden.",
            line11: "Aussage",
            line12: "Wir erklären, dass wir als Verantwortlicher für Ihre personenbezogenen Daten alle gesetzlichen Verpflichtungen einhalten, die von den geltenden Rechtsvorschriften, insbesondere dem Gesetz zum Schutz personenbezogener Daten und der DSGVO, gefordert werden, und dass daher:",
            line13: "Wir verarbeiten Ihre personenbezogenen Daten nur auf Grundlage des oben beschriebenen gültigen Rechtsgrundes.",
            line14: "Gemäß Art. 13 DSGVO erfüllen wir hiermit die Informationspflicht gegenüber den betroffenen Personen.",
            line15: "Wir werden Ihnen erlauben und wir werden Sie bei der Ausübung und Erfüllung Ihrer Rechte nach dem neuen Datenschutzgesetz und der DSGVO unterstützen.",
            line16: "Lehrfächer:",
            line17: "Vertragliche und vorvertragliche Handelspartner",
            line18: "Kunden des Betreibers",
            line19: "Mitarbeiter des Betreibers",
            line20: "Zwecke der Verarbeitung:",
            line21: "Wir verarbeiten personenbezogene Daten, die Sie uns selbst anvertrauen, zum Zwecke der Erfüllung der folgenden Zwecke:",
            line22: "Vertrags-/Vorvertragspartner und Kunden des Betreibers:",
            line23: "Benennung der Vertragspartei",
            line24: "Erbringung der vereinbarten Leistung",
            line25: "Wie lange erfassen wir Ihre personenbezogenen Daten?",
            line26: "Der Verantwortliche garantiert, dass die von der betroffenen Person im betreffenden Vertrag oder auf andere Weise zur Verfügung gestellten personenbezogenen Daten in den Informationssystemen gemäß dem Grundsatz der Minimierung der Speicherung verarbeitet werden, und für den Fall, dass der Zweck der Verarbeitung entfällt, garantiert der Verantwortliche, die personenbezogenen Daten zu löschen. Für den Fall, dass diese personenbezogenen Daten zu einem anderen als dem in diesem Artikel oben vorgesehenen Zweck verarbeitet werden, wird die betroffene Person vor einer solchen Verarbeitung über diesen Zweck sowie über die Rechtsgrundlage dieser Verarbeitung informiert.",
            line27: "Der Verantwortliche garantiert, dass die personenbezogenen Daten von Mitarbeitern für die in dieser Richtlinie genannten Zwecke für einen maximalen Zeitraum der Dauer des Beschäftigungsverhältnisses oder, wenn eine der besonderen Vorschriften dies erfordert, für den in dieser Rechtsvorschrift festgelegten Zeitraum verarbeitet werden.",
            line28: "Sicherheit und Datenschutz",
            line29: "Wir schützen personenbezogene Daten so weit wie möglich. Wir beschützen sie, als wären sie unsere eigenen. Wir haben alle möglichen technischen und organisatorischen Maßnahmen getroffen, um den Missbrauch, die Beschädigung oder die Zerstörung Ihrer personenbezogenen Daten zu verhindern.",
            line30: "Weitergabe personenbezogener Daten an Dritte (Vermittler, Empfänger)",
            line31: "Ihre personenbezogenen Daten können von autorisierten Personen eingesehen werden, die vom Verantwortlichen zur Verschwiegenheit verpflichtet und im Bereich der Verarbeitungssicherheit geschult sind.",
            line32: "Wir verwalten die meisten Verarbeitungsvorgänge selbst und benötigen dafür keine 3rd-Parteien.\n" +
                "Um bestimmte Verarbeitungsvorgänge zu gewährleisten, die wir nicht selbst erbringen können, nutzen wir die Dienste von Vermittlern, die auf die Verarbeitung spezialisiert sind und durch einen Vermittlungsvertrag gemäß DSGVO verpflichtet sind.",
            line33: "Hierbei handelt es sich um Anbieter folgender Plattformen und Dienste:",
            line34: "Anbieter von Buchhaltungsdienstleistungen",
            line35: "Anbieter von IT-Dienstleistungen",
            line36: "Übermittlung von Daten außerhalb der Europäischen Union",
            line37: "Wir verarbeiten Daten ausschließlich in der Europäischen Union oder in Ländern, die ein von der Europäischen Kommission festgelegtes Schutzniveau gewährleisten.",
            line38: "Ihre Rechte in Bezug auf den Schutz personenbezogener Daten:",
            line39: "Sie haben mehrere Rechte in Bezug auf den Schutz personenbezogener Daten. Wenn Sie eines dieser Rechte ausüben möchten, kontaktieren Sie uns bitte über die oben genannte E-Mail-Adresse.",
            line40: "Sie haben",
            line41: "das Recht auf Auskunft,",
            line42: "die bereits durch diese Datenschutzerklärung erfüllt wird. - Informationspflicht",
            line43: "Dank des",
            line44: "Auskunftsrechts",
            line45: "können Sie uns jederzeit einladen und wir werden Ihnen innerhalb von 30 Tagen nachweisen, welche Ihrer personenbezogenen Daten wir verarbeiten und warum.",
            line46: "Wenn sich für Sie etwas ändert oder Sie feststellen, dass Ihre personenbezogenen Daten veraltet oder unvollständig sind, haben Sie",
            line47: "das Recht, Ihre personenbezogenen Daten hinzuzufügen und zu ändern.",
            line48: "Sie können",
            line49: "das Recht auf Einschränkung der Verarbeitung",
            line50: "ausüben, wenn Sie der Meinung sind, dass wir Ihre unrichtigen Daten verarbeiten, glauben, dass wir unrechtmäßig verarbeiten, aber nicht alle Daten löschen möchten oder wenn Sie der Verarbeitung widersprechen.",
            line51: "Sie können den Umfang der personenbezogenen Daten oder die Zwecke der Verarbeitung",
            line52: "einschränken",
            line53: "",
            line54: "Recht auf Löschung (zum Vergessenwerden):",
            line55: "Ihr nächstes Recht ist das Recht auf Löschung, in Ermangelung einer Rechtsgrundlage, die uns berechtigt, oder einer gesetzlichen Verpflichtung, die uns zur Verarbeitung Ihrer personenbezogenen Daten anweist. In diesem Fall löschen wir alle Ihre personenbezogenen Daten aus Ihrem System, auch aus dem System aller Teilauftraggeber und Vorleistungen innerhalb der gesetzlichen Frist.",
            line56: "Recht auf Datenübertragbarkeit",
            line57: "in den in der Verordnung vorgesehenen Fällen haben Sie das Recht, die Sie betreffenden personenbezogenen Daten, die Sie betreffen und die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, wobei dieses Recht die Rechte und Freiheiten anderer Personen nicht beeinträchtigt.",
            line58: "Sie können der Verarbeitung Ihrer personenbezogenen Daten bei dem Verantwortlichen für Direktmarketingzwecke, die auf der Grundlage eines berechtigten Interesses erfolgt, jederzeit",
            line59: "widersprechen.",
            line60: "",
            line61: "Recht auf Widerruf der Einwilligung:",
            line62: "wenn die Verarbeitung Ihrer personenbezogenen Daten auf einer Einwilligung beruht, haben Sie das Recht, Ihre Einwilligung zur Verarbeitung personenbezogener Daten für den Zweck, für den Sie Ihre Einwilligung erteilt haben, jederzeit zu widerrufen.",
            line63: "Beschwerde beim Amt für den Schutz personenbezogener Daten:",
            line64: "Wenn Sie der Meinung sind, dass wir Ihre Daten nicht in Übereinstimmung mit dem Gesetz behandeln, haben Sie das Recht, sich mit Ihrer Beschwerde jederzeit an das Amt für den Schutz personenbezogener Daten zu wenden.",
            line65: "Die Aufsichtsbehörde in der Slowakischen Republik ist das Amt für den Schutz personenbezogener Daten mit Sitz in Hraničná 12, 820 07 Bratislava 27, dataprotection.gov.sk",
            line66: "Wir würden uns sehr freuen, wenn Sie uns diesen Verdacht zunächst mitteilen, damit wir etwas dagegen tun und etwaiges Fehlverhalten korrigieren können.",
            line67: "Wie können Sie diese Rechte ausüben?",
            line68: "Sie können uns mit Ihrer Anfrage auf eine der folgenden Arten kontaktieren:",
            line69: "Email:",
            line70: "schriftlich oder persönlich durch Lieferung an:",
            line71: "Wenn Sie der Ansicht sind, dass Ihre Rechte auf Schutz personenbezogener Daten verletzt wurden, haben Sie das Recht, eine Beschwerde bei der Aufsichtsbehörde einzureichen, bei der es sich um das Amt für den Schutz personenbezogener Daten unter",
            line72: "Slowakische Republik",
            line73: "Identifikationsdaten:",
            line74: "UID",
            line75: "Das Sekretariat des Amtes:",
            line76: "Registrierung:",
            line77: "Montag - Donnerstag:",
            line78: "Freitag:",
            line79: "WO KÖNNEN SIE UNS KONTAKTIEREN?",
            line80: "Wenn Sie Kommentare, Fragen oder Wünsche bezüglich der Verwendung Ihrer personenbezogenen Daten haben oder Fragen zu dieser Datenschutzerklärung haben, kontaktieren Sie uns bitte unter der folgenden E-Mail-Adresse: osadsky.jan@josgroup.sk oder in den im Abschnitt Kontakt angegebenen Kontaktdaten. Wir freuen uns über Ihre Fragen und Anregungen zu dieser Aussage."
        }
    });
    const [lang, setLang] = useState(navigator.language);
    useEffect(() => {
        if(window.localStorage.getItem('change_language') === 'sk')
            setLang('sk');
        else if(window.localStorage.getItem('change_language') === 'de')
            setLang('de');
        else if(window.localStorage.getItem('change_language') === 'en')
            setLang('en');
    })
    localization.setLanguage(lang);

    return (
        <>
            <SEO title={localization.title} />
            <Layout>
                <div className="slider-area slider-style-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title display-one">{localization.title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <p>
                                    {localization.line1}
                                </p>
                                <p>
                                    <strong>Jos Group s.r.o. Generála M. R. Štefánika 8 90101 Malacky {localization.country} {localization.id}: 44 246 501</strong>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.contact}:</strong><br/>
                                    <strong>Email: </strong>osadsky.jan@josgroup.sk<br/>
                                    <strong>{localization.mobil}: </strong>+421 948 047 077<br/>
                                    {localization.line2}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.processing}:</strong><br/>
                                    {localization.line3}:
                                <ul>
                                    <li>{localization.line4}</li>
                                    <p>{localization.line5}</p>
                                    <li>{localization.line6}</li>
                                    <p>{localization.line7}</p>
                                    <li>{localization.line8}</li>
                                    <li>{localization.line9}</li>
                                    <li>{localization.line10}</li>
                                </ul>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line11}:</strong><br/>
                                    {localization.line12}
                                <ul>
                                    <li>{localization.line13}</li>
                                    <li>{localization.line14}</li>
                                    <li>{localization.line15}</li>
                                </ul>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line16}</strong><br/>
                                <ul>
                                    <li>{localization.line17}</li>
                                    <li>{localization.line18}</li>
                                    <li>{localization.line19}</li>
                                </ul>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line20}</strong><br/>
                                    {localization.line21}<br/>
                                    <ul>
                                        <li><strong>{localization.line22}</strong></li>
                                        <li>{localization.line23}</li>
                                        <li>{localization.line24}</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line25}</strong><br/>
                                    <ul>
                                        <li>{localization.line26}</li>
                                        <li>{localization.line27}</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line28}</strong><br/>
                                    {localization.line29}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line30}</strong><br/>
                                    {localization.line31}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    {localization.line32}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    {localization.line33}
                                    <ul>
                                        <li>{localization.line34}</li>
                                        <li>{localization.line35}</li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line36}</strong><br/>
                                    {localization.line37}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line38}</strong><br/>
                                    {localization.line39}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    {localization.line40} <strong>{localization.line41}</strong> {localization.line42}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    {localization.line43} <strong>{localization.line44}</strong> {localization.line45}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    {localization.line46} <strong>{localization.line47}</strong>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    {localization.line48} <strong>{localization.line49}</strong> {localization.line50}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    {localization.line51} <strong>{localization.line52}</strong> {localization.line53}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line54}</strong> {localization.line55}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line56}</strong> {localization.line57}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    {localization.line58} <strong>{localization.line59}</strong> {localization.line60}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line61}</strong> {localization.line62}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line63}</strong> {localization.line64} <strong>{localization.line65}.</strong> {localization.line66}
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line67}</strong><br/>
                                    <strong>{localization.line68}</strong><br/>
                                    <strong>{localization.line69} osadsky.jan@josgroup.sk {localization.line70} Jos Group s.r.o. Generála M. R. Štefánika 8 90101 Malacky {localization.country}, {localization.id}: 44 246 501</strong><br/>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line71}</strong><br/>
                                    Hraničná 12<br/>
                                    820 07 Bratislava 27<br/>
                                    {localization.line72}<br/>
                                    {localization.line73}<br/>
                                    {localization.id}: 36064220<br/>
                                    {localization.line74}: 2021685985<br/>
                                    {localization.line75}<br/>
                                    +421 /2 3231 3214<br/>
                                    <strong>E-mail:</strong> statny.dozor@pdp.gov.sk<br/>
                                    <strong>{localization.line76}</strong> {localization.line77} 8:00 - 15:00<br/>
                                    {localization.line78} 8:00 - 14:00<br/>
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <p>
                                    <strong>{localization.line79}</strong><br/>
                                    {localization.line80}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Privacy
