import React, {useEffect, useState} from 'react';
import ContactForm from "./ContactForm";
import {FiHeadphones, FiInfo, FiMail, FiMapPin} from "react-icons/fi";
import LocalizedStrings from "react-localization";

const ContactOne = () => {
    const localization = new LocalizedStrings({
        en:{
            phone: "Contact Phone Number",
            email: "Our Email Address",
            address: "Our Location",
            state: "Slovakia",
            bank_details : "Bank details",
            account_number: "Account number",
            bank_code: "Bank code",
            id: "ID",
            vat: "VAT",
            bank: "Bank"
        },
        sk:{
            phone: "Telefónne číslo",
            email: "Email",
            address: "Naša adresa",
            state: "Slovensko",
            bank_details : "Bankové údaje",
            account_number: "Číslo účtu",
            bank_code: "Kód banky",
            id: "IČO",
            vat: "DIČ",
            bank: "Banka"
        },
        de:{
            phone: "Mobil",
            email: "Email",
            address: "Anschrift",
            state: "Slowakei",
            bank_details : "Bankverbindung",
            account_number: "Kontonummer",
            bank_code: "Bankleitzahl",
            id: "ID",
            vat: "UID",
            bank: "Bank"
        }
    });
    const [lang, setLang] = useState(navigator.language);
    useEffect(() => {
        if(window.localStorage.getItem('change_language') === 'sk')
            setLang('sk');
        else if(window.localStorage.getItem('change_language') === 'de')
            setLang('de');
        else if(window.localStorage.getItem('change_language') === 'en')
            setLang('en');
    })
    localization.setLanguage(lang);

    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{localization.phone}</h4>
                                        <p><a href="tel:+421 948 047 077">+421 948 047 077</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{localization.email}</h4>
                                        <p><a href="mailto:osadsky.jan@josgroup.sk">osadsky.jan@josgroup.sk</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{localization.address}</h4>
                                        <p>Generála M. R. Štefánika 8<br /> 90101 Malacky <br /> Slovakia</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-10 col-md-11 col-12">
                    <div className="rn-address">
                        <div className="icon">
                            <FiInfo />
                        </div>
                        <div className="inner">
                            <h4>{localization.bank_details}</h4>
                            <p><strong>{localization.bank}: </strong> Tatra Banka</p>
                            <p><strong>{localization.account_number}: </strong> 262 586 1141</p>
                            <p><strong>{localization.bank_code}: </strong> 1100</p>
                            <p><strong>IBAN: </strong>  SK15 1100 0000 0026 2586 1141</p>
                            <p><strong>SWIFT: </strong>  TATRSKBX</p>
                            <p><strong>{localization.id}: </strong> 44 246 501</p>
                            <p><strong>{localization.vat}: </strong> SK2022633316</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
            </div>
        </>
    )
};
export default ContactOne;