import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderTwo from '../common/header/HeaderTwo';
import FooterOne from '../common/footer/FooterOne';

import CalltoActionSix from "../elements/calltoaction/CalltoActionSix";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import AboutTwo from "../elements/about/AboutTwo";
import SlipThree from "../elements/split/SlipThree";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import FooterThree from "../common/footer/FooterThree";
import LocalizedStrings from "react-localization";
var BlogListData = BlogClassicData.slice(0, 3);



const Technology = () => {
    const localization = new LocalizedStrings({
        en:{
            title: "Technologies"
        },
        sk:{
            title: "Technológie"
        },
        de:{
            title: "Technologien"
        }
    });
    const [lang, setLang] = useState(navigator.language);
    useEffect(() => {
        if(window.localStorage.getItem('change_language') === 'sk')
            setLang('sk');
        else if(window.localStorage.getItem('change_language') === 'de')
            setLang('de');
        else if(window.localStorage.getItem('change_language') === 'en')
            setLang('en');
    })
    localization.setLanguage(lang);

    return (
        <>
            <SEO title="Technologies" />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                {/* Start Slider area  */}
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/tech7-min.png)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-left">
                                    <h4 className="subtitle">JOS GROUP</h4>
                                    <h1 className="title display-one">{localization.title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}


                <Separator />

                <AboutTwo />

                {/* Start Elements Area  */}
                {/*<div className="rwt-split-area">
                    <div className="wrapper">
                        <SlipThree />
                    </div>
                </div>*/}
                {/* End Elements Area  */}



                {/*<Separator />
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Our Latest News."
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}

                <FooterThree/>
            </main>
        </>
    )
}

export default Technology;
