import React, {useEffect, useState} from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";
import LocalizedStrings from "react-localization";


const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=xshEZzpS4CQ',
        ],
    }
]

const AboutFour = ({image}) => {
    const localization = new LocalizedStrings({
        en:{
            title_part1: "JOS Group specializes in",
            title_part2: "IT Technologies",
            about1_title: "company",
            about1: "JOS GROUP Ltd. was founded in 2008 and is concerned with information technology services with focus on the german speaking contries, expecially Germany and Austria.",
            about2_title: "experience",
            about2: "We have experiences with many projects from different branches of industry like automotive, railway traffic (ETCS), automatisation and food processing."
        },
        sk:{
            title_part1: "JOS Group sa špecializuje na",
            title_part2: "IT Technológie",
            about1_title: "spoločnosť",
            about1: "Spoločnosť JOS GROUP s.r.o. bola založená v roku 2008 a zaoberá sa službami v oblasti informačných technológií s orientáciou na trhy v nemecky hovoriacich krajinách, hlavne v Nemecku a Rakúsku.",
            about2_title: "skúsenosti",
            about2: "Máme skúsenosti z viacerými projektami v oblasti automobilového priemyslu, železničnej dopravy (ETCS), automatizácie ako aj potravinárskeho priemyslu."
        },
        de:{
            title_part1: "JOS Group specialisiert auf",
            title_part2: "IT Technologien",
            about1_title: "Kompanie",
            about1: "JOS GROUP Company Ltd. Es wurde 2008 gegründet und beschäftigt sich mit Dienstleistungen in der Informationstechnologie mit Schwerpunkt auf den Märkten in den deutschsprachigen Ländern, vor allem in Deutschland und Österreich.",
            about2_title: "Erfahrungen",
            about2: "Wir haben Erfahrung mit verschiedenen Projekten in der Automobilindustrie , Schienenverkehr (ETCS), Automatisierung und Lebensmittelindustrie."
        }
    });
    const [lang, setLang] = useState(navigator.language);
    useEffect(() => {
        if(window.localStorage.getItem('change_language') === 'sk')
            setLang('sk');
        else if(window.localStorage.getItem('change_language') === 'de')
            setLang('de');
        else if(window.localStorage.getItem('change_language') === 'en')
            setLang('en');
    })
    localization.setLanguage(lang);

    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    {/*<div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>*/}
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">{localization.title_part1}<br /><strong>{localization.title_part2}</strong></h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">{localization.about1_title}</h4>
                                            <p className="text">{localization.about1}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">{localization.about2_title}</h4>
                                            <p className="text">{localization.about2}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
